<template>
  <div class="page">
    <!--查询框-->
    <div class="search-box section page-header" style="margin-bottom: 1px;">
      <el-form ref="searchForm" :inline="true" :model="query" size="medium">
        <el-form-item prop="parkingLotId">
          <!--          <SuggestParking v-model="query.parkingLotId" />-->
          <el-input
              v-model="query.nameOrPhone"
              placeholder="请输入巡检员姓名/手机号查询"
              prefix-icon="el-icon-search"
              class="width260 margin-right10 margin-left38"
          />
        </el-form-item>
        <el-form-item prop="workStatus">
          <el-select
              v-model="query.status"
              clearable
              placeholder="在职状态"
              class="width260"
          >
            <el-option label="在职" value="0"></el-option>
            <el-option label="离职" value="1"></el-option>
            <!-- <el-option label="已离职" value="2"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ summaryData.total }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">在职：</div>
          <div class="text">{{ summaryData.onJob }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">离职：</div>
          <div class="text">{{ summaryData.offJob }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div class="row_new padding-bottom19">
        <el-button type="primary" size="small" @click="goAdd">新增</el-button>
      </div>
<!--      v-loading="isLoading"-->
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column
            align="center"
            label="巡检员编号"
            prop="inspectorCode"
            width="260"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="姓名"
            prop="inspectorName"
            width="200"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="手机号"
            prop="phone"
            width="200"
        >
        </el-table-column>
        <el-table-column align="center" label="性别" width="100">
          <template slot-scope="scope">
            {{
              scope.row.sex === 0
                  ? "未设置"
                  : scope.row.sex === 1
                      ? "男"
                      : "女"
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="工作状态" width="100">
          <template slot-scope="scope">
            {{ workStatus[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="签到车场"
            prop="parkingLotAssign"
        >
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="280">
          <template v-if="scope.row.status === 0" slot-scope="scope">
            <el-button
                class="common-text"
                size="small"
                type="text"
                @click="goEdit(scope.row, false)"
            >详情
            </el-button
            >
            <el-button
                class="common-text"
                size="small"
                type="text"
                @click="goQuit(scope.row)"
            >离职
            </el-button
            >
            <el-button
                class="common-text"
                size="small"
                type="text"
                @click="goEdit(scope.row, true)"
            >编辑
            </el-button
            >
<!--            <el-button-->
<!--                v-if="hasRights('001004003001005')"-->
<!--                class="common-text"-->
<!--                size="small"-->
<!--                type="text"-->
<!--                @click="goChangePassword(scope.row)"-->
<!--            >修改密码-->
<!--            </el-button-->
<!--            >-->
<!--            <el-button-->
<!--                v-if="hasRights('001004003001006')"-->
<!--                class="common-text"-->
<!--                size="small"-->
<!--                type="text"-->
<!--                @click="goForceQuit(scope.row)"-->
<!--            >强制签退-->
<!--            </el-button-->
<!--            >-->
            <!-- <el-button
              type="text"
              size="small"
              class="common-text"
              @click="goQuit(scope.row.id)"
              >生成二维码</el-button
            > -->
            <!-- <el-button
              type="text"
              size="small"
              class="common-text"
              @click="goQuit(scope.row.id)"
              >认证</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加巡检员弹窗 -->
    <el-dialog
        :before-close="onInspectorDialogBeforeClosed"
        :title="title"
        :visible.sync="inspectorDialogVisible"
        append-to-body
        class="common-dialog"
        width="400px"
    >
      <el-form
          ref="model"
          v-loading="isInspectorLoading"
          :inline="true"
          :model="model"
          :rules="rules"
          size="medium"
      >
        <el-row>
          <el-col span="24">
            <el-form-item label="姓名" prop="inspectorName" :required="isEdit">
              <el-input
                  v-model="model.inspectorName"
                  :readonly="!isEdit"
                  :disabled="!isEdit"
                  :clearable="isEdit"
                  placeholder="姓名"
                  prefix-icon=""
                  class="width400"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col span="24">
            <el-form-item
                label="手机号码"
                prop="phone"
                :required="isEdit"
            >
              <el-input
                  v-model="model.phone"
                  :disabled="!isEdit"
                  :readonly="!isEdit"
                  :clearable="isEdit"
                  placeholder="手机号码"
                  prefix-icon=""
                  class="width400"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col span="24">
            <el-form-item label="性别" prop="sex" :required="isEdit">
              <el-radio-group
                  :disabled="!isEdit"
                  v-model="model.sex">
                <el-radio-button label="1">男</el-radio-button>
                <el-radio-button label="2">女</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col span="24">
<!--            prop="email"  :required="isEdit"-->
            <el-form-item label="邮箱">
              <el-input
                  v-model="model.email"
                  :disabled="!isEdit"
                  :readonly="!isEdit"
                  :clearable="isEdit"
                  placeholder="邮箱地址"
                  prefix-icon=""
                  class="width400"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col span="24">
<!--            prop="avatar"-->
            <el-form-item label="选择头像">
              <el-upload
                  :disabled="!isEdit"
                  ref="headImageUpload"
                  :http-request="doHeadImageUpload"
                  :show-file-list="false"
                  action=""
                  class="avatar-uploader"
                  list-type="picture"
              >
                <Picture
                    v-if="model.avatar"
                    :attachId="model.avatar"
                    class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="isEdit" @click="cancelAddOrEdit">取 消</el-button>
        <el-button type="primary" @click="saveAddOrEdit">确定</el-button>
      </div>
    </el-dialog>

    <!-- 修改密码弹窗 -->
    <el-dialog
        :visible.sync="changePasswordDialogVisible"
        append-to-body
        class="common-dialog"
        title="修改密码"
        width="400px"
    >
      <el-form
          ref="changeData"
          v-loading="isChangeLoading"
          :inline="true"
          :model="changeData"
          :rules="rulesChangeData"
          size="medium"
      >
        <el-form-item
            label="修改密码"
            label-width="100px"
            prop="newPassword"
            required
        >
          <el-input
              v-model="changeData.newPassword"
              placeholder="请输入新密码"
              prefix-icon=""
              show-password
              class="width260"
              type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelChangePassword">取 消</el-button>
        <el-button type="primary" @click="saveNewPassword">确定</el-button>
      </div>
    </el-dialog>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import SuggestParking from "@/components/SuggestParking";
// import Settlement from "@/components/Settlement";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import {doUploadImage} from "@/api/parking";
import {
  changePassword,
  forcedSignOut,
  inspectorList,
  departInspector,
  addInspector,
  editInspector,
  inspectorDetail,
} from "@/api/patrolInspection";
import {findParkingBySettlementId} from "@/api/settlementManage";
import Picture from "@/components/Picture";
import {doExport} from "@/api/common";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";
// import {selectCurledHailLog} from "@/api/user";

export default {
  name: "people_manage",
  mixins: [BaseMixin, Permission],
  components: {
    // SuggestParking,
    // Settlement,
    Picture,
  },
  data() {
    return {
      summaryData:{},
      isEdit: false, //判断是否来自编辑，第一次不清空车场
      query: {
        nameOrPhone: "",
        settlementId: "",
        userCode: "",
        status: "0",
        // authenticationStatus: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      dataList: [],
      workStatus: {
        0: "在职",
        1: "离职",
      },
      inspectorDialogVisible: false, //添加编辑巡检员弹窗
      isInspectorLoading: false, //提交添加编辑loading
      title: "",
      options: [], //车场列表选项
      model: {
        id: "",
        // certificateNumber: "",
        settlementId: "",
        parkingLotIds: "",
        inspectorName: "",
        phone: "",
        sex: "1",
        email: "",
        avatar: "",
      },
      rules: {
        // settlementId: [{required: true, message: "请选择结算方"}],
        // parkingLotIds: [{required: true, message: "请选择停车场"}],
        inspectorName: [{required: true, message: "姓名不能为空"}],
        phone: [{required: true, message: "手机号不能为空"}],
        email: [{required: true, message: "邮箱不能为空"}],
        // certificateNumber: [{required: true, message: "身份证号不能为空"}],
        sex: [{required: true, message: "请选择性别"}],
        avatar: [{required: true, message: "请选择头像"}],
      },
      changePasswordDialogVisible: false, //修改密码弹窗
      isChangeLoading: false, //提交修改密码loading
      changePasswordId: "",
      changeData: {
        newPassword: "",
      },
      rulesChangeData: {
        newPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("密码不能为空"));
              } else if (
                  !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/.test(value)
              ) {
                callback(
                    new Error(
                        "必须包含大小写字母和数字的组合,可以使用特殊字符,长度在8-16之间"
                    )
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  //监听选中的结算方，查询当前结算方关联车场
  watch: {
    "model.settlementId"(newVal) {
      if (this.isEdit !== true) {
        this.model.parkingLotIds = "";
      }
      const pageSize = 9999;
      const pageNo = 1;
      const id = newVal;
      findParkingBySettlementId(id, pageSize, pageNo).then((res) => {
        this.isEdit = false;
        if (res && res.code === 30 && res.result) {
          this.options = res.returnObject.list;
        }
      });
    },
  },
  methods: {
    //监听选中的结算方，查询当前结算方关联车场
    // getParkingLot() {
    //   this.model.parkingLotIds = "";
    //   const pageSize = 9999;
    //   const pageNo = 1;
    //   const id = this.model.settlementId;
    //   findParkingBySettlementId(id, pageSize, pageNo).then((res) => {
    //     if (res && res.code === 30 && res.result) {
    //       this.options = res.returnObject.list;
    //     }
    //   });
    // },
    //重置查询条件
    resetQueryForm() {
      this.query = {
        settlementId: "",
        userCode: "",
        workStatus: "0",
        // authenticationStatus: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      };
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      checkToken()
          .then(async res => {
            console.log('checkToken', res)
            if (!res || res.code !== 30) {
              MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                confirmButtonText: '重新登录',
                callback: () => {
                  window.localStorage.clear()
                  window.$vue.$router.push('/login')
                }
              });
            } else {
              this.isLoading = true;
              const params = this.paramFormat(this.query);
              const res = await inspectorList(params);
              this.isLoading = false;
              if (res && res.code === 30 && res.result) {
                const returnObject = res.returnObject;
                this.summaryData = res.returnObject;
                this.dataList = returnObject.records;
                this.pagination.totalSize = returnObject.total;
                this.pagination.totalPages = returnObject.pages;
                this.form.exportSize = returnObject.total;
              }
            }
          })
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // window.open("http://172.16.69.129:81" + "/examineManageController/getPersonnelManage?" + objectToQueryString(params));
      doExport(
          "/examineManageController/getPersonnelManage",
          params,
          "巡检人员.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //添加巡检员
    goAdd() {
      this.title = "添加巡检员";
      this.isEdit = true;
      this.model = {
        id: "",
        // certificateNumber: "",
        settlementId: "",
        parkingLotIds: "",
        inspectorName: "",
        phone: "",
        sex: "1",
        email: "",
        avatar: "",
      };
      this.inspectorDialogVisible = true;
    },
    // 添加/维护巡检员信息弹窗关闭时清理表单
    onInspectorDialogBeforeClosed(done) {
      this.$refs.model.resetFields();
      done();
    },
    //编辑巡检员
    goEdit(e, isEdit) {
      inspectorDetail(e.id).then((res) => {
        if (res && res.code === 30 && res.result) {
          var returnObject = res.returnObject
          console.log(returnObject)
          this.isEdit = isEdit;
          if(isEdit){
            this.title = "编辑巡检员";
          }else{
            this.title = "巡检员详情";
          }
          this.model  = returnObject
          // this.model.id = returnObject.id;
          // this.model.trueName = returnObject.trueName
          // this.model.parkingLotId = returnObject.parkingLotId
          // // this.model.settlementId = e.settlementId;
          // // this.model.parkingLotIds = JSON.parse(e.parkingLotIds)[0];
          // this.model.mobileNum = returnObject.mobileNum;
          // this.model.sex = Number(returnObject.sex);
          // this.model.certificateNumber = returnObject.certificateNumber
          // this.model.inspectorName = returnObject.inspectorName;
          // this.model.avatar = returnObject.avatar;
          // this.model.email = returnObject.email;
          this.inspectorDialogVisible = true;
        }
      });

    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = (error) => reject(error); //失败返回失败信息
      });
    },
    // 上传头像图片
    async doHeadImageUpload(file) {
      const isLimit = file.file.size / 1024 < 1000*5;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过5M! 请重新上传");
        return;
      }
      // 创建文件流
      const formData = new FormData();
      formData.append('picIo', file.file);
      formData.append('name', file.file.name);
      const res = await doUploadImage(formData);
      if (res && res.code === 30 && res.result) {
        this.model.avatar = res.returnObject.id;
      }
    },

    //取消添加或编辑巡检员
    cancelAddOrEdit() {
      this.inspectorDialogVisible = false;
      this.$refs.model.resetFields();
    },
    //确定添加或编辑巡检员
    saveAddOrEdit() {
      console.log(this.model)
      if(this.isEdit){
        this.$refs.model.validate((valid) => {
          if (valid) {
            // const id = this.id;
            // const avatar = this.model.avatar;
            // const mailbox = this.model.mailbox;
            // const name = this.model.name;
            // const phoneNum = this.model.phoneNum;
            // const parkingLot = this.model.parkingLotIds;
            // const parkingLotIds = [];
            // parkingLotIds.push(parkingLot);
            // const sex = this.model.sex;
            // const body = {
            //   avatar: avatar,
            //   id: id,
            //   mailbox: mailbox,
            //   name: name,
            //   phoneNum: phoneNum,
            //   parkingLotIds: parkingLotIds,
            //   sex: sex,
            // };
            // console.log(body);
            this.isInspectorLoading = true;
            const parkingId = [];
            parkingId.push(this.model.parkingLotIds);
            this.model.parkingLotIds = parkingId;
            console.log(this.model);
            if(this.model.id===''){
              addInspector(this.model).then((res) => {
                setTimeout(() => {
                  this.isInspectorLoading = false;
                  if (res && res.code === 30 && res.result) {
                    this.$message.success("添加成功！");
                    this.inspectorDialogVisible = false;
                    this.$refs.model.resetFields();
                    this.doSearch();
                  }
                }, 2000);
              });
            }else{
              editInspector(this.model).then((res) => {
                setTimeout(() => {
                  this.isInspectorLoading = false;
                  if (res && res.code === 30 && res.result) {
                    this.$message.success("编辑成功！");
                    this.inspectorDialogVisible = false;
                    this.$refs.model.resetFields();
                    this.doSearch();
                  }
                }, 2000);
              });
            }
          } else {
            return false;
          }
        });
      }else{
        this.inspectorDialogVisible = false;
      }
    },
    // 离职操作
    goQuit(e) {
      this.$confirm("确定要给该人员办理离职吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const id = e.id;
        this.isLoading = true;
        departInspector(id).then((res) => {
          setTimeout(() => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("办理离职成功！");
              this.doSearch();
            }
          }, 2000);
        });
      });
    },

    //强制签退操作
    goForceQuit(e) {
      this.$confirm("确定要给该人员强制签退吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const id = e.id;
        forcedSignOut(id).then((res) => {
          if (res && res.code === 30 && res.result) {
            this.$message.success("强制签退成功！");
            this.doSearch();
          }
        });
      });
    },

    //修改密码
    goChangePassword(e) {
      this.changePasswordId = e.id;
      this.changePasswordDialogVisible = true;
    },
    //取消修改密码
    cancelChangePassword() {
      this.changePasswordDialogVisible = false;
      this.$refs.changeData.resetFields();
    },
    //确定修改密码
    saveNewPassword() {
      this.$refs.changeData.validate((valid) => {
        if (valid) {
          const id = this.changePasswordId;
          const password = this.changeData.newPassword;
          const body = {
            id: id,
            password: password,
          };
          this.isChangeLoading = true;
          changePassword(body).then((res) => {
            this.isChangeLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("修改密码成功！");
              this.changePasswordDialogVisible = false;
              this.$refs.changeData.resetFields();
              this.doSearch();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}

.avatar-uploader .el-upload:hover {
  border-color: #0768FD;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-form-item--medium .el-form-item__label{
  width: 100px;
}
</style>
